// translations.js
const translations = {
    en: {
      howToPlay: "How To Play",
      tapLanguage: "Tap the language you want to play",
      guessWord: "Guess the word in each word length",
      selectWordLength: "Select a word length first",
      guessTheWord: "Guess the word",
      oneCorrectAnswer: "Only 1 correct answer per word length",
      hint: "Hint:",
      singleLetterSets: "- Single letter sets are at the end of your word",
      useSetOnce: "- In each word length you can only use a set once",
      examples: "Examples",
      theWordIs: "- The 3 letter word to find is CAT",
      tapThen: "- Tap \"CA\" then \"T\"",
      pressSubmit: "- Press submit",
      wrongAnswers: "Wrong answers",
      correctGreen: "- Correct sets will be Green",
      incorrectRed: "- Incorrect sets will be red",
      newPuzzle: "A new puzzle is available at midnight",
      youveWon: "You've won",
      game: "game",
      shareGame: "Share the game"
    },
    fr: {
      howToPlay: "Comment Jouer",
      tapLanguage: "Choisissez la langue avec laquelle vous voulez jouer en tapent le drapeau",
      guessWord: "Devinez le mot pour chaque longueur de mot",
      selectWordLength: "Sélectionnez d'abord une longueur de mot",
      guessTheWord: "Devinez le mot",
      oneCorrectAnswer: "Seulement une réponse correcte par longueur de mot",
      hint: "Indice :",
      singleLetterSets: "- Les groupes de lettres uniques sont à la fin de votre mot",
      useSetOnce: "- Pour chaque longueur de mot, vous pouvez utiliser un ensemble une seule fois",
      examples: "Exemples",
      theWordIs: "- Le mot de 3 lettres à trouver est PRÉ",
      tapThen: "- Touchez \"PR\" puis \"É\"",
      pressSubmit: "- Appuyez sur valider",
      wrongAnswers: "Réponses fausses",
      correctGreen: "- Les groupes corrects seront en Vert",
      incorrectRed: "- Les groupes incorrects seront en rouge",
      newPuzzle: "Un nouveau jeu est disponible à minuit",
      youveWon: "Vous avez gagné",
      game: "jeux",
      shareGame: "Partagez le jeu"
    },
    es: {
        howToPlay: "Cómo jugar",
        tapLanguage: "Toca el idioma con el que quieres jugar",
        guessWord: "Adivina la palabra en cada longitud de palabra",
        selectWordLength: "Selecciona primero una longitud de palabra",
        guessTheWord: "Adivina la palabra",
        oneCorrectAnswer: "Solo 1 respuesta correcta por longitud de palabra",
        hint: "Pista:",
        singleLetterSets: "- Los conjuntos de una sola letra están al final de tu palabra",
        useSetOnce: "- En cada longitud de palabra puedes usar un conjunto solo una vez",
        examples: "Ejemplos",
        theWordIs: "- La palabra de 4 letras a encontrar es GATO",
        tapThen: "- Toca \"GA\" luego \"TO\"",
        pressSubmit: "- Presiona enviar",
        wrongAnswers: "Respuestas incorrectas",
        correctGreen: "- Los conjuntos correctos serán Verdes",
        incorrectRed: "- Los conjuntos incorrectos serán rojos",
        newPuzzle: "Un nuevo rompecabezas está disponible a medianoche",
        youveWon: "Has ganado",
        game: "juego",
        shareGame: "Comparte el juego"
      },
      pt: {
        howToPlay: "Como Jogar",
        tapLanguage: "Toque no idioma que deseja jogar",
        guessWord: "Adivinhe a palavra em cada comprimento de palavra",
        selectWordLength: "Selecione primeiro um comprimento de palavra",
        guessTheWord: "Adivinhe a palavra",
        oneCorrectAnswer: "Apenas 1 resposta correta por comprimento de palavra",
        hint: "Dica:",
        singleLetterSets: "- Conjuntos de uma única letra estão no final da sua palavra",
        useSetOnce: "- Em cada comprimento de palavra, você pode usar um conjunto apenas uma vez",
        examples: "Exemplos",
        theWordIs: "- A palavra de 4 letras a encontrar é GATO",
        tapThen: "- Toque em \"GA\" e depois em \"TO\"",
        pressSubmit: "- Pressione enviar",
        wrongAnswers: "Respostas erradas",
        correctGreen: "- Conjuntos corretos serão Verdes",
        incorrectRed: "- Conjuntos incorretos serão vermelhos",
        newPuzzle: "Um novo puzzle está disponível à meia-noite",
        youveWon: "Você ganhou",
        game: "jogo",
        shareGame: "Compartilhe o jogo"
    },
    tr: {
      howToPlay: "Nasıl Oynanır",
      tapLanguage: "Oynamak istediğiniz dili seçin",
      guessWord: "Her kelime uzunluğundaki kelimeyi tahmin edin",
      selectWordLength: "Önce bir kelime uzunluğu seçin",
      guessTheWord: "Kelimeyi tahmin et",
      oneCorrectAnswer: "Her kelime uzunluğu için yalnızca 1 doğru cevap",
      hint: "İpucu:",
      singleLetterSets: "- Tek harf kümeleri kelimenizin sonunda yer alır",
      useSetOnce: "- Her kelime uzunluğunda bir küme yalnızca bir kez kullanılabilir",
      examples: "Örnekler",
      theWordIs: "- Bulunması gereken 4 harfli kelime KEDİ",
      tapThen: "- \"KE\" sonra \"Dİ\"ye dokunun",
      pressSubmit: "- Gönder'e basın",
      wrongAnswers: "Yanlış cevaplar",
      correctGreen: "- Doğru kümeler Yeşil olacak",
      incorrectRed: "- Yanlış kümeler Kırmızı olacak",
      newPuzzle: "Yeni bir bulmaca gece yarısında kullanılabilir",
      youveWon: "Kazandınız",
      game: "oyun",
      shareGame: "Oyunu paylaş"
    }
      
  };
  export default translations;
  